import { Grid } from "@mui/material";
import CardHolder from "./widget-card";
import { WorldClock } from "./widgets/clock";
import TimeZone2 from "./widgets/world-timezone2";
import { CountryShipmentCount } from "./widgets/countryShipmentCount";
import YearlyShipmentCount from "./widgets/yearlyShipmentCount";
import FrachtSuspense from "../../commonControls/FrachtSuspense";
import FrachtAxisStatusCount from "./widgets/frachtAxisStatus";
import StatusCardHolder from "./status-widget";
import DeliveryPurchaseOrder from "./widgets/deliveryPurOrder";
import DelayWidgets from "./delayWidgets";
import MostShippedContainer from "./widgets/mostShippedContainer";
import AvgTransitTime from "./widgets/avgTransitTime";
import MontlyVolume from "./widgets/montlyVolume";
import DeliveryCompliance from "./deliveryComplieanceWidget";
import DwellTransShipWidget from "./widgets/DwellTransShipWidget";
import OperationalDataQualityV2Widget from "./widgets/odqV2WidgetCard";
import ContainerCount from "./widgets/containerCount";

export const WIDGET_LIST = [
  {
    id: "MonthlyShipment",
    displayName: "Monthly Shipment Overview",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Monthly Shipment Overview"
          size={2}
          showAction={true}
          id="MonthlyShipment"
        >
          <YearlyShipmentCount />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 2,
  },
  {
    id: "CountryWiseShipment",
    displayName: "Country-wise Shipment Overview",
    content: (
      <>
        <FrachtSuspense>
          <CardHolder
            title="Country-wise Shipment Overview"
            size={2}
            showAction={true}
            id="CountryWiseShipment"
          >
            <CountryShipmentCount />
          </CardHolder>
        </FrachtSuspense>
      </>
    ),
    size: 1,
  },
  {
    id: "WorldClock",
    displayName: "Clock - World Clock",
    content: (
      <>
        <FrachtSuspense>
          <CardHolder
            title="Clock - World Clock"
            size={2}
            showAction={true}
            id="WorldClock"
          >
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <WorldClock />
            </Grid>
          </CardHolder>
        </FrachtSuspense>
      </>
    ),
    size: 2,
  },
  {
    id: "WorldMapFracht",
    displayName: "World - Fracht Office",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="World - Fracht Office"
          size={1}
          showAction={true}
          id="WorldMapFracht"
        >
          <TimeZone2 />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "StatusWiseShipment",
    displayName: "Status Overview",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Status Overview"
          size={1}
          showAction={true}
          id="StatusWiseShipment"
        >
          <FrachtAxisStatusCount />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "OrderStatus",
    displayName: "Order Status Information",
    content: (
      <>
        <FrachtSuspense>
          <StatusCardHolder
            title="Order Status Information"
            size={1}
            showAction={true}
            id="OrderStatus"
          ></StatusCardHolder>
        </FrachtSuspense>
      </>
    ),
    size: 1,
  },
  {
    id: "MostShippedCountries",
    displayName: "Volume - Top 5 Countries",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Volume - Top 5 Countries"
          size={2}
          showAction={true}
          id="MostShippedCountries"
        >
          <DeliveryPurchaseOrder />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 2,
  },
  {
    id: "DelayCards",
    displayName: "Delay Cards",
    content: (
      <FrachtSuspense>
        <DelayWidgets
          title="Delay Cards"
          size={1}
          showAction={true}
          id="DelayCards"
        ></DelayWidgets>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "MostShippedContainerByOriginCountries",
    displayName: "Origin Container Count",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Origin Container Count"
          size={1}
          showAction={true}
          id="MostShippedContainerByOriginCountries"
        >
          <MostShippedContainer locationType="origin" />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "MostShippedContainerByDestinationCountries",
    displayName: "Destination Container Count",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Destination Container Count"
          size={1}
          showAction={true}
          id="MostShippedContainerByDestinationCountries"
        >
          <MostShippedContainer locationType="destination" />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "AverageTransitTime",
    displayName: "Average Transit Time (Port to Port)",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Average Transit Time (Port to Port)"
          size={1}
          showAction={true}
          id="AverageTransitTime"
          tooltipMessage="Last 90 Days"
        >
          <AvgTransitTime />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "MonthlyVolumeOverview",
    displayName: "Monthly Volume Overview",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Monthly Volume Overview"
          size={1}
          showAction={true}
          id="MonthlyVolumeOverview"
        >
          <MontlyVolume />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "DeliveryComplainceCard",
    displayName: "Delivery Complaince Widget",
    content: (
      <FrachtSuspense>
        <DeliveryCompliance
          title="Delivery Complaince Widget"
          size={1}
          showAction={true}
          id="DeliveryComplainceCard"
        ></DeliveryCompliance>
      </FrachtSuspense>
    ),
    size: 1,
  },

  {
    id: "DwellAtTransshipPort",
    displayName: "Dwell at Transship Port",
    content: (
      <FrachtSuspense>
        <DwellTransShipWidget
          title="Dwell at Transship Port"
          size={1}
          showAction={true}
          id="DwellAtTransshipPort"
        />
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "OperationalDataQualityV2Widget",
    displayName: "Operational Data Quality V2.0 Widget",
    content: (
      <FrachtSuspense>
        <OperationalDataQualityV2Widget
          title="Operational Data Quality V2.0 Widget"
          size={2}
          showAction={false}
          id="OperationalDataQualityV2Widget"
        />
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "DestinationContainerCount",
    displayName: "Destination Container Count",
    content: (
      <FrachtSuspense>
        <CardHolder
          title="Destination container count"
          source="MyFracht - Track & Trace"
          size={1}
          showAction={true}
          id="DestinationContainerCount"
        >
          <ContainerCount locationType="destination" />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
  {
    id: "OriginContainerCount",
    displayName: "Origin Container Count",
    content: (
      <FrachtSuspense>
        <CardHolder
          source="MyFracht - Track & Trace"
          title="Origin container count"
          size={1}
          showAction={true}
          id="OriginContainerCount"
        >
          <ContainerCount locationType="origin" />
        </CardHolder>
      </FrachtSuspense>
    ),
    size: 1,
  },
];
