import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
  useCallback,
} from "react";
import DataGrid, {
  Column,
  FilterRow,
  ColumnFixing,
  StateStoring,
  Search,
  HeaderFilter,
  Scrolling,
  Sorting,
  Paging,
  Selection,
} from "devextreme-react/data-grid";
import { Grid } from "@mui/material";
import "devextreme/dist/css/dx.light.css";
import "./gridStyle.css";
import { useRef } from "react";
import Loader from "../../components/UI/Loader/Loader";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

const GridControl = (props) => {
  const dataGridRef = useRef(null);
  const [data, setdata] = useState([]);
  useImperativeHandle(props.childRef, () => ({
    clearSort() {
      clearAllSorting();
    },
    resetGridScroll() {
      resetScroll();
    },
    clearFilter() {
      clearFilters();
    },
    refreshGrid() {
      refreshGridInstance();
    },
    exportGridData(isCustomDownload = false) {
      onExporting(isCustomDownload);
    },
    getGridCurrentData() {
      return getGridData();
    },
  }));
  const gridSettings = useMemo(() => {
    return props.gridConfiguration;
  }, [props.gridConfiguration]);

  const renderDefaultColums = useCallback(() => {
    return props.gridColums?.map((col, index) => {
      return <Column key={index} {...col} />;
    });
  }, [props.gridColums]);

  const onGridChange = (e) => {
    props.onGridChange(e);
  };
  const clearAllSorting = () => {
    dataGridRef?.current?.instance?.clearSorting();
  };

  const clearFilters = () => {
    dataGridRef?.current?.instance?.clearFilter();
  };
  useEffect(() => {
    setdata(props.gridData);
  }, [props.gridData]);

  const resetScroll = () => {
    dataGridRef?.current?.instance?.getScrollable()?.scrollTo(0);
  };

  const refreshGridInstance = () => {
    dataGridRef?.current?.instance?.refresh();
  };
  const onExporting = (isCustomDownload) => {
    excelExport(dataGridRef?.current?.instance, isCustomDownload);
  };

  const getGridData = () => {
    return dataGridRef?.current?.instance?.getDataSource().items();
  };

  const excelExport = (DataGrid, isCustomDownload = false) => {
    try {
      var ExcelJSWorkbook = new Workbook();
      var worksheet = ExcelJSWorkbook.addWorksheet(gridSettings?.Id);
      var columns = isCustomDownload
        ? DataGrid.option("columns")
        : DataGrid.getVisibleColumns();

      var headerRow = worksheet.addRow();
      headerRow.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "007DBA" },
      };
      headerRow.font = {
        size: 12,
        underline: false,
        bold: true,
        color: { argb: "FFFFFF" },
      };
      if (columns[0].caption === undefined) {
        columns.shift();
      }
      for (let i = 0; i < columns.length; i++) {
        let currentColumnWidth = DataGrid.option().columns[i].width;
        worksheet.getColumn(i + 1).width =
          currentColumnWidth !== undefined ? currentColumnWidth / 6 : 20;
        let cell = headerRow.getCell(i + 1);
        cell.value = columns[i].caption;
      }

      DataGrid.getController("data")
        .loadAll()
        .then(function (allItems) {
          for (let i = 0; i < allItems.length; i++) {
            var dataRow = worksheet.addRow();
            if (allItems[i].rowType === "data") {
              dataRow.outlineLevel = 1;
              if (i % 2 === 0) {
                dataRow.fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "E6E6E6" },
                };
              }
            }
            if (isCustomDownload) {
              for (let j = 0; j < columns.length; j++) {
                let cell = dataRow.getCell(j + 1);
                cell.value = allItems[i].data[columns[j].dataField];
              }
            } else {
              for (let j = 0; j < allItems[i].values.length; j++) {
                let cell = dataRow.getCell(j + 1);
                if (typeof allItems[i].values[j] === "object") {
                  let isData = Date.parse(allItems[i].values[j]);
                  if (isNaN(isData)) {
                    cell.value = "";
                  } else {
                    const cellValue = moment(allItems[i].values[j]).format(
                      "MM/DD/YYYY"
                    );
                    cell.value = cellValue === "01/01/0001" ? "" : cellValue;
                  }
                } else if (typeof allItems[i].values[j] === "boolean") {
                  cell.value = allItems[i].values[j].toString();
                } else {
                  cell.value = allItems[i].values[j]
                    ? allItems[i].values[j]
                    : "";
                }
              }
            }
          }

          // const rowCount = worksheet.rowCount;
          // worksheet.mergeCells(`A${rowCount}:I${rowCount + 1}`);
          // worksheet.getRow(1).font = { bold: true };
          // worksheet.getCell(`A${rowCount}`).font = {
          //   name: "Comic Sans MS",
          //   family: 4,
          //   size: 20,
          //   underline: true,
          //   bold: true,
          // };

          //worksheet.getCell(`A${rowCount}`).value = "Custom Footer here";

          ExcelJSWorkbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `${
                gridSettings?.Id.toUpperCase() || "gridData"
              }-${moment().format("DD MMM YYYY")}.xlsx`
            );
          });
        });
    } catch (e) {
      console.log("Some Error Occured", e);
    }
  };

  return (
    <Grid item xs={12} style={{ width: "100%", position: "relative" }}>
      {props.showLoader && <Loader />}
      <DataGrid
        ref={dataGridRef}
        id={gridSettings?.Id || "demo-grid"}
        dataSource={data}
        cacheEnabled={true}
        showBorders={gridSettings.showBorders}
        remoteOperations={gridSettings.remoteOperations}
        showColumnLines={gridSettings.showColumnLines}
        showRowLines={gridSettings.showRowLines}
        rowAlternationEnabled={gridSettings.rowAlternationEnabled}
        wordWrapEnabled={gridSettings.wordWrapEnabled}
        height={gridSettings.height}
        width={gridSettings.width}
        allowColumnResizing={gridSettings.allowColumnResizing}
        allowColumnReordering={gridSettings.allowColumnReordering}
        dataRowRender={props.rowTemplate}
        onSelectionChanged={props.onSelectionChanged}
        onPagingChange={(e) => console.log(e)}
        onOptionChanged={onGridChange}
        onRowPrepared={props.onRowPrepared}
        columnMinWidth={gridSettings.columnMinWidth}
        columnAutoWidth={gridSettings.columAutoWidth}
        onCellClick={(e) => {
          props.onCellClick(e);
        }}
        onCellPrepared={(e) => {
          props?.onCellPrepared(e);
        }}
        noDataText={props.noDataText}
        onFocusedCellChanging={props?.onFocusedCellChanging}
        onContentReady={props?.onGridContentReday}
      >
        {props.gridConfiguration.defaultColums && renderDefaultColums()}
        {!props.gridConfiguration.defaultColums && props.children}
        <Sorting mode={gridSettings.sortingMode} />
        <Selection mode={gridSettings.selectionMode} />
        <FilterRow visible={gridSettings.filterRow} />
        <Scrolling useNative={true} mode="virtual" rowRenderingMode="virtual" />
        <Paging defaultPageSize={gridSettings?.pageSize} />
        <HeaderFilter visible={gridSettings.headerFilter}>
          <Search enabled={true} />
        </HeaderFilter>
        <ColumnFixing enabled={gridSettings.allowColumFixing} />
        <StateStoring
          enabled={gridSettings.statePersistence}
          type="localStorage"
          storageKey={`grid-${gridSettings?.Id}`}
        />
      </DataGrid>
    </Grid>
  );
};

export default GridControl;
