import {
  GET_ANALYTICS_DATA_FAILURE,
  GET_ANALYTICS_DATA_SUCCESS,
  GET_DWELL_TRANSSHIP_FAILURE,
  GET_DWELL_TRANSSHIP_SUCCESS,
  GET_FOURTH_PL_DATA_FAILURE,
  GET_FOURTH_PL_DATA_SUCCESS,
  GET_ORGANIZATION_NAME,
  GET_WIDGET_OPTIONS_FAILURE,
  GET_WIDGET_OPTIONS_SUCCESS,
  SET_AVG_TRANSIT_TIME,
  SET_CARD_LOADING,
  SET_COUNTRY_WISE_SHIPMENT_COUNT,
  SET_DELAY_COUNT,
  SET_DELIVERY_COMPLIANCE,
  SET_DELIVERY_PURCHASE_ORDER_COUNT,
  SET_DESTINATION_CONTAINER_COUNT,
  SET_FOURTH_PL_DATA,
  SET_MONTHLY_SHIPMENT_VOLUME,
  SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION,
  SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN,
  SET_ODQCARD_LOADING,
  SET_ODQV1_COUNT,
  SET_ORDER_STATUS_COUNT,
  SET_ORIGIN_CONTAINER_COUNT,
  SET_STATUS_WISE_COUNT,
  SET_WIDGET_LOADING,
  SET_WIDGET_OPTIONS,
  SET_YEARLY_SHIPMENT_COUNT,
  UPDATE_CLOCK_DATA,
  UPDATE_WIDGET_LAYOUT,
} from "./actionTypes";
import { WIDGET_LIST } from "../../containers/Dashboard/widget-list";

const widgetListSize = JSON.parse(localStorage.getItem("widgetListSize"));

const initialState = {
  widgetLoading: false,
  cardLoading: false,
  odqcardLoading: false,
  widgetsList: widgetListSize
    ? widgetListSize
    : {
        MonthlyShipment: {
          layout: 2,
          id: "MonthlyShipment",
          isSelected: true,
          displayName: "Monthly Shipment Overview",
        },
        WorldMapFracht: {
          layout: 1,
          id: "WorldMapFracht",
          isSelected: true,
          displayName: "World - Fracht Office",
        },
        CountryWiseShipment: {
          layout: 1,
          id: "CountryWiseShipment",
          isSelected: true,
          displayName: "Country-wise Shipment Overview",
        },
        WorldClock: {
          layout: 2,
          id: "WorldClock",
          isSelected: true,
          displayName: "Clock - World Clock",
        },
        StatusWiseShipment: {
          layout: 2,
          id: "StatusWiseShipment",
          isSelected: true,
          displayName: "Status Overview",
        },
        OrderStatus: {
          layout: 1,
          id: "OrderStatus",
          isSelected: true,
          displayName: "Order Status Information",
        },
        MostShippedCountries: {
          layout: 2,
          id: "MostShippedCountries",
          isSelected: true,
          displayName: "Volume - Top 5 Countries",
        },
        DelayCards: {
          layout: 1,
          id: "DelayCards",
          isSelected: true,
          displayName: "Delay Cards",
        },
        MostShippedContainerByOriginCountries: {
          layout: 1,
          id: "MostShippedContainerByOriginCountries",
          isSelected: true,
          displayName: "Origin Container Count",
        },
        MostShippedContainerByDestinationCountries: {
          layout: 1,
          id: "MostShippedContainerByDestinationCountries",
          isSelected: true,
          displayName: "Destination Container Count",
        },
        AverageTransitTime: {
          layout: 1,
          id: "AverageTransitTime",
          isSelected: true,
          displayName: "Average Transit Time (Port to Port)",
        },
        MonthlyVolumeOverview: {
          layout: 1,
          id: "MonthlyVolumeOverview",
          isSelected: true,
          displayName: "Monthly Volume Overview",
        },
        DeliveryComplainceCard: {
          layout: 1,
          id: "DeliveryComplainceCard",
          isSelected: true,
          displayName: "Delivery Compliance Widget",
        },
        DwellAtTransshipPort: {
          layout: 1,
          id: "DwellAtTransshipPort",
          isSelected: true,
          displayName: "Dwell at Transship Port",
        },
        OperationalDataQualityV2Widget: {
          layout: 2,
          id: "OperationalDataQualityV2Widget",
          isSelected: true,
          displayName: "Operational Data Quality V2.0 Widget",
        },
        OriginContainerCount: {
          layout: 1,
          id: "OriginContainerCount",
          isSelected: true,
          displayName: "Origin Container Count",
        },
        DestinationContainerCount: {
          layout: 1,
          id: "DestinationContainerCount",
          isSelected: true,
          displayName: "Destination Container Count",
        },
      },
  clockDataList: null,
  selectedList: WIDGET_LIST,
  widgetOptions: [],
  fourthPLData: [],
  dwellTransShipData: 0,
  orderStatusCount: null,
  delayCount: null,
  odqV1Count: null,
  deliveryCompliance: -1,
  yearlyShipmentCount: null,
  countryShipmentCount: null,
  deliveryPurchaseOrder: null,
  mostShippedOrigin: null,
  mostShippedDestination: null,
  averageTransitTime: null,
  monthlyShipmentVolume: null,
  statusWiseCount: null,
  dwellSliderValue: 7,
  analyticsData: null,
  originContainerCount: null,
  destinationContainerCount: null,
};

const getSelectedList = (parentOrganizationId) => {
  if (parentOrganizationId !== undefined) {
    switch (parentOrganizationId) {
      case "Lep_ID":
        return WIDGET_LIST;
      default:
        return WIDGET_LIST;
    }
  }
};

const WidgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WIDGET_LOADING:
      return {
        ...state,
        widgetLoading: true,
      };
    case SET_CARD_LOADING:
      return {
        ...state,
        cardLoading: true,
      };
    case SET_ODQCARD_LOADING:
      return {
        ...state,
        odqcardLoading: true,
      };
    case UPDATE_WIDGET_LAYOUT:
      return {
        ...state,
        widgetsList: action.widgetList,
      };

    case UPDATE_CLOCK_DATA:
      return {
        ...state,
        clockDataList: action.clockData,
      };

    case GET_ORGANIZATION_NAME:
      const selectedList = getSelectedList(action.parentOrganizationId);
      return {
        ...state,
        selectedList,
      };

    case GET_WIDGET_OPTIONS_SUCCESS:
      return {
        ...state,
        widgetLoading: false,
        widgetOptions: action.payload,
      };
    case GET_WIDGET_OPTIONS_FAILURE:
      return {
        ...state,
        widgetLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };

    case GET_FOURTH_PL_DATA_SUCCESS:
      return {
        ...state,
        widgetLoading: false,
        fourthPLData: action.payload.result,
      };
    case GET_FOURTH_PL_DATA_FAILURE:
      return {
        ...state,
        widgetLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case SET_FOURTH_PL_DATA:
      return {
        ...state,
        widgetLoading: false,
        fourthPLData: action.payload,
      };
    case SET_WIDGET_OPTIONS:
      return {
        ...state,
        widgetOptions: action.payload,
      };

    case GET_DWELL_TRANSSHIP_SUCCESS:
      return {
        ...state,
        cardLoading: false,
        dwellTransShipData: action.payload.data,
        dwellSliderValue: action.payload.slider,
      };
    case GET_DWELL_TRANSSHIP_FAILURE:
      return {
        ...state,
        cardLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case SET_ORDER_STATUS_COUNT:
      return {
        ...state,
        orderStatusCount: action.payload,
      };
    case SET_DELAY_COUNT:
      return {
        ...state,
        delayCount: action.payload,
      };
    case SET_ODQV1_COUNT:
      return {
        ...state,
        odqcardLoading: false,
        odqV1Count: action.payload,
      };
    case SET_DELIVERY_COMPLIANCE:
      return {
        ...state,
        deliveryCompliance: action.payload,
      };
    case SET_YEARLY_SHIPMENT_COUNT:
      return {
        ...state,
        yearlyShipmentCount: action.payload,
      };
    case SET_COUNTRY_WISE_SHIPMENT_COUNT:
      return {
        ...state,
        countryShipmentCount: action.payload,
      };
    case SET_DELIVERY_PURCHASE_ORDER_COUNT:
      return {
        ...state,
        deliveryPurchaseOrder: action.payload,
      };
    case SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN:
      return {
        ...state,
        mostShippedOrigin: action.payload,
      };
    case SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION:
      return {
        ...state,
        mostShippedDestination: action.payload,
      };

    case SET_AVG_TRANSIT_TIME:
      return {
        ...state,
        averageTransitTime: action.payload,
      };
    case SET_MONTHLY_SHIPMENT_VOLUME:
      return {
        ...state,
        monthlyShipmentVolume: action.payload,
      };
    case SET_STATUS_WISE_COUNT:
      return {
        ...state,
        statusWiseCount: action.payload,
      };

    case GET_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        cardLoading: false,
        analyticsData: action.payload,
      };
    case GET_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        cardLoading: false,
        error: true,
        errorMessage: action.errorMessage,
      };
    case SET_ORIGIN_CONTAINER_COUNT:
      return {
        ...state,
        originContainerCount: action.payload,
      };
    case SET_DESTINATION_CONTAINER_COUNT:
      return {
        ...state,
        destinationContainerCount: action.payload,
      };
    default:
      return state;
  }
};

export default WidgetsReducer;
