import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import TimeZoneMenu from "./timezone-menu";
import { Divider, Tooltip, Typography } from "@mui/material";
import FrachtSuspense from "../../commonControls/FrachtSuspense";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const WidgetConfigureDialog = React.lazy(() => import("./configure-dialog"));

export default function CardHolder(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Card sx={{ width: "100%", borderRadius: "22px" }}>
        <CardHeader
          action={
            props.showAction && (
              <IconButton
                aria-label="settings"
                id="settings"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
            )
          }
          title={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography mr={1} variant="h4">
                {props.title}
                <Typography
                  mr={1}
                  variant="h4"
                  style={{ color: "grey", fontSize: "11px" }}
                >
                  {props.source}
                </Typography>
              </Typography>

              {props?.tooltipMessage && (
                <Tooltip placement="bottom-start" title={props.tooltipMessage}>
                  <InfoOutlinedIcon fontSize="small" />
                </Tooltip>
              )}
            </div>
          }
        />
        <Divider />
        <CardContent>{props.children}</CardContent>
      </Card>
      {props.id === "WorldClock" ? (
        <TimeZoneMenu
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
        />
      ) : (
        <>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenDialog(true);
                handleClose();
              }}
            >
              Configure
            </MenuItem>
          </Menu>
          <FrachtSuspense>
            <WidgetConfigureDialog
              openDialog={openDialog}
              closeDialog={() => setOpenDialog(false)}
              widgetId={props.id}
            />
          </FrachtSuspense>
        </>
      )}
    </>
  );
}
