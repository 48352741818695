import { combineReducers } from "redux";
import LoginUser from "./login/reducer";
import CreateShipment from "./createShipment/reducer";
import ShipmentDetail from "./upload/reducer";
import AutoRate from "./rates/reducer";
import Layout from "./layout/reducer";
import MenuVisibility from "./menuVisibility/reducer";
import AssignContainer from "./assignContainer/reducer";
import EditDetailContainer from "./editDetailPage/reducer";
import MillerCoors from "./millerCoors/reducer";
import MillerCoorsSettings from "./millerCoorsSettings/reducer";
import RailCarScheduler from "./railCarScheduler/reducer";
import ReleaseLock from "./releaseLock/reducer";
import PageHeadDetails from "./pageHeadDetails/reducer";
import ShipmentHistory from "./shipmentHistory/reducer";
import NewOrganization from "./newOrganization/reducer";
import EditOrganization from "./editOrganization/reducer";
import TruckLoad from "./truckload/reducer";
import Notifications from "./notification/reducer";
import EditUserDetails from "./editUser/reducer";
import Role from "./roles/reducer";
import WidgetsReducer from "./widgets/reducer";
import Axis from "./axis/reducer";
import Analytics from "./analytics/reducer";

const rootReducer = combineReducers({
  LoginUser,
  CreateShipment,
  ShipmentDetail,
  AutoRate,
  Layout,
  MenuVisibility,
  AssignContainer,
  EditDetailContainer,
  MillerCoors,
  MillerCoorsSettings,
  RailCarScheduler,
  ReleaseLock,
  PageHeadDetails,
  ShipmentHistory,
  NewOrganization,
  EditOrganization,
  TruckLoad,
  Notifications,
  EditUserDetails,
  Role,
  WidgetsReducer,
  Axis,
  Analytics,
});

export default rootReducer;
