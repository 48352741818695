export const UPDATE_WIDGET_LAYOUT = "UPDATE_WIDGET_LAYOUT";

export const UPDATE_CLOCK_DATA = "UPDATE_CLOCK_DATA";

export const GET_ORGANIZATION_NAME = "GET_ORGANIZATION_NAME";

export const SET_WIDGET_LOADING = "SET_WIDGET_LOADING";
export const SET_CARD_LOADING = "SET_CARD_LOADING";

export const GET_WIDGET_OPTIONS = "GET_WIDGET_OPTIONS";
export const GET_WIDGET_OPTIONS_SUCCESS = "GET_WIDGET_OPTIONS_SUCCESS";
export const GET_WIDGET_OPTIONS_FAILURE = "GET_WIDGET_OPTIONS_FAILURE";

export const GET_FOURTH_PL_DATA = "GET_FOURTH_PL_DATA";
export const GET_FOURTH_PL_DATA_SUCCESS = "GET_FOURTH_PL_DATA_SUCCESS";
export const GET_FOURTH_PL_DATA_FAILURE = "GET_FOURTH_PL_DATA_FAILURE";
export const SET_FOURTH_PL_DATA = "SET_FOURTH_PL_DATA";

export const GET_DWELL_TRANSSHIP = "GET_DWELL_TRANSSHIP";
export const GET_DWELL_TRANSSHIP_SUCCESS = "GET_DWELL_TRANSSHIP_SUCCESS";
export const GET_DWELL_TRANSSHIP_FAILURE = "GET_DWELL_TRANSSHIP_FAILURE";

export const SET_WIDGET_OPTIONS = "SET_WIDGET_OPTIONS";
export const GET_ORDER_STATUS_COUNT = "GET_ORDER_STATUS_COUNT";
export const SET_ORDER_STATUS_COUNT = "SET_ORDER_STATUS_COUNT";
export const GET_DELAY_COUNT = "GET_DELAY_COUNT";
export const SET_DELAY_COUNT = "SET_DELAY_COUNT";
export const GET_DELIVERY_COMPLIANCE = "GET_DELIVERY_COMPLIANCE";
export const SET_DELIVERY_COMPLIANCE = "SET_DELIVERY_COMPLIANCE";

export const SET_ODQCARD_LOADING = "SET_ODQCARD_LOADING";
export const GET_ODQV1_COUNT = "GET_ODQV1_COUNT";
export const SET_ODQV1_COUNT = "SET_ODQV1_COUNT";

export const GET_YEARLY_SHIPMENT_COUNT = "GET_YEARLY_SHIPMENT_COUNT";
export const SET_YEARLY_SHIPMENT_COUNT = "SET_YEARLY_SHIPMENT_COUNT";

export const GET_COUNTRY_WISE_SHIPMENT_COUNT =
  "GET_COUNTRY_WISE_SHIPMENT_COUNT";
export const SET_COUNTRY_WISE_SHIPMENT_COUNT =
  "SET_COUNTRY_WISE_SHIPMENT_COUNT";

export const GET_DELIVERY_PURCHASE_ORDER_COUNT =
  "GET_DELIVERY_PURCHASE_ORDER_COUNT";
export const SET_DELIVERY_PURCHASE_ORDER_COUNT =
  "SET_DELIVERY_PURCHASE_ORDER_COUNT";

export const GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN =
  "GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN";
export const SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN =
  "SET_MOST_SHIPPED_CONTAINER_BY_ORIGIN";
export const GET_MOST_SHIPPED_CONTAINER_BY_DESTINATION =
  "GET_MOST_SHIPPED_CONTAINER_BY_ORIGIN";
export const SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION =
  "SET_MOST_SHIPPED_CONTAINER_BY_DESTINATION";

export const GET_AVG_TRANSIT_TIME = "GET_AVG_TRANSIT_TIME";
export const SET_AVG_TRANSIT_TIME = "SET_AVG_TRANSIT_TIME";

export const GET_MONTHLY_SHIPMENT_VOLUME = "GET_MONTHLY_SHIPMENT_VOLUME";
export const SET_MONTHLY_SHIPMENT_VOLUME = "SET_MONTHLY_SHIPMENT_VOLUME";

export const GET_STATUS_WISE_COUNT = "GET_STATUS_WISE_COUNT";
export const SET_STATUS_WISE_COUNT = "SET_STATUS_WISE_COUNT";

export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const GET_ANALYTICS_DATA_SUCCESS = "GET_ANALYTICS_DATA_SUCCESS";
export const GET_ANALYTICS_DATA_FAILURE = "GET_ANALYTICS_DATA_FAILURE";

export const GET_ORIGIN_CONTAINER_COUNT = "GET_ORIGIN_CONTAINER_COUNT";
export const SET_ORIGIN_CONTAINER_COUNT = "SET_ORIGIN_CONTAINER_COUNT";

export const GET_DESTINATION_CONTAINER_COUNT =
  "GET_DESTINATION_CONTAINER_COUNT";
export const SET_DESTINATION_CONTAINER_COUNT =
  "SET_DESTINATION_CONTAINER_COUNT";
